var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero is-primary is-fullheight login-principal-container"},[_c('div',{staticClass:"hero-head"},[_c('nav',{staticClass:"navbar"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"navbar-menu",attrs:{"id":"navbarMenuHeroA"}},[_c('div',{staticClass:"navbar-end"},[_c('a',{staticClass:"navbar-item",on:{"click":function($event){return _vm.openLogin()}}},[_c('b',{staticClass:"change-login-register-action"},[_vm._v("Iniciar sesión ")])])])])])])]),_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('div',{staticClass:"columns is-centered absolut-center"},[_vm._m(1),_c('div',{staticClass:"stepper-container"},[_c('b-steps',{attrs:{"animated":"","rounded":"","label-position":"bottom","mobile-mode":"compact","type":"is-white","has-navigation":false},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('b-step-item',{attrs:{"step":"1","label":"Información de usuario","type":{ 'is-success': _vm.isUserSucces },"icon":"account-outline"}},[_c('div',{staticClass:"register-form-container"},[_c('b-field',{staticClass:"login-form-field",attrs:{"label":"Nombre completo del usuario","type":{ 'is-danger': _vm.errorRegisterUser.fullName },"message":{
                    'El nombre completo no es válido':
                      _vm.errorRegisterUser.fullName,
                  }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Michael Scott"},model:{value:(_vm.registerUser.fullName),callback:function ($$v) {_vm.$set(_vm.registerUser, "fullName", $$v)},expression:"registerUser.fullName"}})],1),_c('b-field',{staticClass:"login-form-field",attrs:{"label":"Email","type":{ 'is-danger': _vm.errorRegisterUser.email },"message":{
                    'El email no es válido': _vm.errorRegisterUser.email,
                  }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: michael@mail.com"},model:{value:(_vm.registerUser.email),callback:function ($$v) {_vm.$set(_vm.registerUser, "email", $$v)},expression:"registerUser.email"}})],1),_c('b-field',{attrs:{"label":"Contraseña","type":{ 'is-danger': _vm.errorRegisterUser.password },"message":{
                    'La contraseña no es válido': _vm.errorRegisterUser.password,
                  }}},[_c('b-input',{attrs:{"placeholder":"contraseña","type":"password","password-reveal":""},model:{value:(_vm.registerUser.password),callback:function ($$v) {_vm.$set(_vm.registerUser, "password", $$v)},expression:"registerUser.password"}})],1),_c('div',{staticClass:"special-margin password-meter-div"},[_c('password-meter',{attrs:{"password":_vm.registerUser.password},on:{"score":_vm.onPasswordScore}})],1),_c('b-field',{staticClass:"login-form-field",attrs:{"label":"Confirmar contraseña","type":{ 'is-danger': _vm.errorRegisterUser.confirmPassword },"message":{
                    'La contraseña no es igual a la anterior no es válido':
                      _vm.errorRegisterUser.confirmPassword,
                  }}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","placeholder":"contraseña "},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('vue-recaptcha',{ref:"recaptcha",staticClass:"special-margin",attrs:{"sitekey":"6LfbycAeAAAAALw78YPyBs4O_JbVxDVaPUXblKL0"},on:{"verify":_vm.verifyCaptcha}}),_c('b-button',{staticClass:"register-button",on:{"click":function($event){return _vm.registerNextStep(0)}}},[_vm._v("Continuar")])],1)]),_c('b-step-item',{attrs:{"step":"2","label":"Información de compañía","type":{ 'is-success': _vm.isCompanySuccess },"icon":"domain"}},[_c('div',{staticClass:"register-form-container"},[_c('b-field',{staticClass:"login-form-field caps-input",attrs:{"label":"Nombre de la compañía","type":{ 'is-danger': _vm.errorRegisterUser.companyMainName },"message":{
                    'El nombre de la compañía no es valido':
                      _vm.errorRegisterUser.companyMainName,
                  }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Compañía"},model:{value:(_vm.registerUser.companyMainName),callback:function ($$v) {_vm.$set(_vm.registerUser, "companyMainName", $$v)},expression:"registerUser.companyMainName"}})],1),_c('b-field',{staticClass:"login-form-field caps-input",attrs:{"label":"RFC de la compañía (Opcional)"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: XEXX010101000"},model:{value:(_vm.registerUser.companyRFC),callback:function ($$v) {_vm.$set(_vm.registerUser, "companyRFC", $$v)},expression:"registerUser.companyRFC"}})],1),_c('b-button',{staticClass:"register-button",on:{"click":function($event){return _vm.registerNextStep(1)}}},[_vm._v("Continuar")])],1)]),_c('b-step-item',{attrs:{"step":"3","label":"Información de pago","type":{ 'is-success': _vm.isPayentSuccess },"icon":"card-bulleted-outline"}},[_c('div',{staticClass:"register-form-container"},[_c('div',{staticClass:"year-payment-container"},[_c('b-switch',{attrs:{"type":"is-success"},model:{value:(_vm.professionalServicesActive),callback:function ($$v) {_vm.professionalServicesActive=$$v},expression:"professionalServicesActive"}},[_vm._v(" Planes de servicios profesionales ")])],1),_c('div',{staticClass:"payment-announce special-margin"},[_c('p',[_vm._v(" El cobro de la primera mensualidad o anualidad del plan seleccionado sera realizado una vez terminada la prueba gratuita de 3 meses ")])]),(!_vm.professionalServicesActive)?_c('div',{staticClass:"pricing-cards-container special-margin"},[_c('div',{staticClass:"single-price-card single-price-card-yellow",class:{
                      'selected-price': _vm.selectedPlan === 1,
                      'selected-price-option-selected': _vm.selectedPlan !== null,
                    },on:{"click":function($event){return _vm.selectPlan(1)}}},[_c('p',{staticClass:"single-price-title"},[_vm._v("Plan Básico")]),_c('hr',{staticClass:"single-price-divider"}),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "$ 499" : "$ 5,988")+" ")]),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "/Mes" : "/Año")+" ")]),_c('p',{staticClass:"single-price-description"},[_vm._v("(IVA incluido)")]),_c('ul',{staticClass:"single-price-list"},[_c('li',[_vm._v("1 usuario incluido")]),_c('li',[_vm._v("1 almacén incluido")]),_c('li',[_vm._v("1 lista de precios")]),_c('li',[_vm._v("1000 timbres mensuales")])])]),_c('div',{staticClass:"single-price-card single-price-card-blue",class:{
                      'selected-price': _vm.selectedPlan === 2,
                      'selected-price-option-selected': _vm.selectedPlan !== null,
                    },on:{"click":function($event){return _vm.selectPlan(2)}}},[_c('p',{staticClass:"single-price-title"},[_vm._v("Plan profesional")]),_c('hr',{staticClass:"single-price-divider"}),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "$ 999" : "$11,988")+" ")]),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "/Mes" : "/Año")+" ")]),_c('p',{staticClass:"single-price-description"},[_vm._v("(IVA incluido)")]),_c('ul',{staticClass:"single-price-list"},[_c('li',[_vm._v("1 usuario incluido")]),_c('li',[_vm._v("2 almacén incluido")]),_c('li',[_vm._v("1 lista de precios")]),_c('li',[_vm._v("2000 timbres mensuales")])])]),_c('div',{staticClass:"single-price-card single-price-card-green",class:{
                      'selected-price': _vm.selectedPlan === 3,
                      'selected-price-option-selected': _vm.selectedPlan !== null,
                    },on:{"click":function($event){return _vm.selectPlan(3)}}},[_c('p',{staticClass:"single-price-title"},[_vm._v("Plan Pro+")]),_c('hr',{staticClass:"single-price-divider"}),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "$ 3,999.0" : "$ 47,988.0")+" ")]),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "/Mes" : "/Año")+" ")]),_c('p',{staticClass:"single-price-description"},[_vm._v("(IVA incluido)")]),_c('ul',{staticClass:"single-price-list"},[_c('li',[_vm._v("5 usuario incluido")]),_c('li',[_vm._v("3 almacén incluido")]),_c('li',[_vm._v("3 lista de precios")]),_c('li',[_vm._v("5000 timbres mensuales")])])]),_c('div',{staticClass:"single-price-card single-price-card-purple",class:{
                      'selected-price': _vm.selectedPlan === 4,
                      'selected-price-option-selected': _vm.selectedPlan !== null,
                    },on:{"click":function($event){return _vm.selectPlan(4)}}},[_c('p',{staticClass:"single-price-title"},[_vm._v("Plan Master")]),_c('hr',{staticClass:"single-price-divider"}),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "$ 16,560" : "$ 198,720")+" ")]),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "/Mes" : "/Año")+" ")]),_c('p',{staticClass:"single-price-description"},[_vm._v("(IVA incluido)")]),_c('ul',{staticClass:"single-price-list"},[_c('ul',{staticClass:"single-price-list"},[_c('li',[_vm._v("10 usuario incluido")]),_c('li',[_vm._v("3 almacén incluido")]),_c('li',[_vm._v("100 lista de precios")]),_c('li',[_vm._v("10000 timbres mensuales")])])])])]):_vm._e(),(_vm.professionalServicesActive)?_c('div',{staticClass:"pricing-cards-container special-margin"},[_c('div',{staticClass:"single-price-card single-price-card-yellow",class:{
                      'selected-price': _vm.selectedPlan === 5,
                      'selected-price-option-selected': _vm.selectedPlan !== null,
                    },on:{"click":function($event){return _vm.selectPlan(5)}}},[_c('p',{staticClass:"single-price-title"},[_vm._v("Plan Básico Servicios")]),_c('hr',{staticClass:"single-price-divider"}),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "$ 499" : "$ 5,988")+" ")]),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "/Mes" : "/Año")+" ")]),_c('p',{staticClass:"single-price-description"},[_vm._v("(IVA incluido)")]),_c('ul',{staticClass:"single-price-list"},[_c('li',[_vm._v("1 usuario incluido")]),_c('li',[_vm._v("1 almacén incluido")]),_c('li',[_vm._v("50 timbres mensuales")])])]),_c('div',{staticClass:"single-price-card single-price-card-green",class:{
                      'selected-price': _vm.selectedPlan === 6,
                      'selected-price-option-selected': _vm.selectedPlan !== null,
                    },on:{"click":function($event){return _vm.selectPlan(6)}}},[_c('p',{staticClass:"single-price-title"},[_vm._v("Plan Pro+ Servicios")]),_c('hr',{staticClass:"single-price-divider"}),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "$ 1,500" : "$ 18,000")+" ")]),_c('p',{staticClass:"single-price-price"},[_vm._v(" "+_vm._s(!_vm.yearlyPaymentActive ? "/Mes" : "/Año")+" ")]),_c('p',{staticClass:"single-price-description"},[_vm._v("(IVA incluido)")]),_c('ul',{staticClass:"single-price-list"},[_c('li',[_vm._v("5 usuario incluido")]),_c('li',[_vm._v("3 almacén incluido")]),_c('li',[_vm._v("200 timbres mensuales")])])])]):_vm._e(),_c('b-button',{staticClass:"register-button",attrs:{"disabled":_vm.selectedPlan === null},on:{"click":function($event){return _vm.registerNextStep(2)}}},[_vm._v("Registrarse")])],1)])],1)],1),_vm._m(2),_c('div',{staticClass:"change-register-container"},[_c('p',{staticClass:"change-login-register-action",on:{"click":function($event){return _vm.openLogin()}}},[_vm._v(" ¿Ya tienes una cuenta? Puedes iniciar sesión con el nombre de tu empresa y tu correo ")])])])])]),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-brand"},[_c('a',{staticClass:"navbar-item",attrs:{"href":"https://www.coconutcontrol.com/"}},[_c('img',{attrs:{"src":require("@/assets/img/logo-final.png")}})]),_c('span',{staticClass:"navbar-burger burger",attrs:{"data-target":"navbarMenuHeroA"}},[_c('span'),_c('span'),_c('span')])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-half"},[_c('p',{staticClass:"title",attrs:{"data-testid":"login-title"}},[_vm._v(" Bienvenido a Coconut Control ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"divider-container-register"},[_c('hr',{staticClass:"solid-divider"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-foot"},[_c('nav',{staticClass:"tabs utilities-list"},[_c('div',{staticClass:"container"},[_c('ul',[_c('li',{staticClass:"is-active"},[_c('a')]),_c('li',[_c('a',[_vm._v("Almacenes")])]),_c('li',[_c('a',[_vm._v("Control de inventario")])]),_c('li',[_c('a',[_vm._v("Control de artículos")])]),_c('li',[_c('a',[_vm._v("Compras")])]),_c('li',[_c('a',[_vm._v("Control de clientes")])]),_c('li',[_c('a',[_vm._v("Venta")])]),_c('li',[_c('a',[_vm._v("Bancos")])]),_c('li',[_c('a',[_vm._v("Tesorería")])]),_c('li',[_c('a',[_vm._v("Calendario")])])])])])])}]

export { render, staticRenderFns }