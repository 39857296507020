<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item" @click="openLogin()">
                <b class="change-login-register-action">Iniciar sesión </b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half">
            <p class="title" data-testid="login-title">
              Bienvenido a Coconut Control
            </p>
          </div>

          <div class="stepper-container">
            <b-steps
              v-model="currentStep"
              animated
              rounded
              label-position="bottom"
              mobile-mode="compact"
              type="is-white"
              :has-navigation="false"
            >
              <b-step-item
                step="1"
                label="Información de usuario"
                :type="{ 'is-success': isUserSucces }"
                icon="account-outline"
              >
                <div class="register-form-container">
                  <b-field
                    label="Nombre completo del usuario"
                    class="login-form-field"
                    :type="{ 'is-danger': errorRegisterUser.fullName }"
                    :message="{
                      'El nombre completo no es válido':
                        errorRegisterUser.fullName,
                    }"
                  >
                    <b-input
                      placeholder="Ejemplo: Michael Scott"
                      v-model="registerUser.fullName"
                    ></b-input>
                  </b-field>

                  <b-field
                    label="Email"
                    class="login-form-field"
                    :type="{ 'is-danger': errorRegisterUser.email }"
                    :message="{
                      'El email no es válido': errorRegisterUser.email,
                    }"
                  >
                    <b-input
                      placeholder="Ejemplo: michael@mail.com"
                      v-model="registerUser.email"
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Contraseña"
                    :type="{ 'is-danger': errorRegisterUser.password }"
                    :message="{
                      'La contraseña no es válido': errorRegisterUser.password,
                    }"
                  >
                    <b-input
                      placeholder="contraseña"
                      type="password"
                      v-model="registerUser.password"
                      password-reveal
                    ></b-input>
                  </b-field>
                  <div class="special-margin password-meter-div">
                    <password-meter
                      :password="registerUser.password"
                      @score="onPasswordScore"
                    />
                  </div>
                  <b-field
                    label="Confirmar contraseña"
                    class="login-form-field"
                    :type="{ 'is-danger': errorRegisterUser.confirmPassword }"
                    :message="{
                      'La contraseña no es igual a la anterior no es válido':
                        errorRegisterUser.confirmPassword,
                    }"
                  >
                    <b-input
                      type="password"
                      password-reveal
                      placeholder="contraseña "
                      v-model="confirmPassword"
                    ></b-input>
                  </b-field>
                  <vue-recaptcha
                    ref="recaptcha"
                    sitekey="6LfbycAeAAAAALw78YPyBs4O_JbVxDVaPUXblKL0"
                    @verify="verifyCaptcha"
                    class="special-margin"
                  >
                  </vue-recaptcha>
                  <b-button class="register-button" @click="registerNextStep(0)"
                    >Continuar</b-button
                  >
                </div>
              </b-step-item>

              <b-step-item
                step="2"
                label="Información de compañía"
                :type="{ 'is-success': isCompanySuccess }"
                icon="domain"
              >
                <div class="register-form-container">
                  <b-field
                    label="Nombre de la compañía"
                    class="login-form-field caps-input"
                    :type="{ 'is-danger': errorRegisterUser.companyMainName }"
                    :message="{
                      'El nombre de la compañía no es valido':
                        errorRegisterUser.companyMainName,
                    }"
                  >
                    <b-input
                      placeholder="Ejemplo: Compañía"
                      v-model="registerUser.companyMainName"
                    ></b-input>
                  </b-field>
                  <b-field
                    label="RFC de la compañía (Opcional)"
                    class="login-form-field caps-input"
                  >
                    <b-input
                      placeholder="Ejemplo: XEXX010101000"
                      v-model="registerUser.companyRFC"
                    ></b-input>
                  </b-field>
                  <b-button class="register-button" @click="registerNextStep(1)"
                    >Continuar</b-button
                  >
                </div>
              </b-step-item>

              <b-step-item
                step="3"
                label="Información de pago"
                :type="{ 'is-success': isPayentSuccess }"
                icon="card-bulleted-outline"
              >
                <div class="register-form-container">
                  <!--
                  <div class="year-payment-container">
                    <b-switch v-model="yearlyPaymentActive" type="is-success">
                      Pago anual
                    </b-switch>
                  </div>
                  -->

                  <div class="year-payment-container">
                    <b-switch
                      v-model="professionalServicesActive"
                      type="is-success"
                    >
                      Planes de servicios profesionales
                    </b-switch>
                  </div>

                  <div class="payment-announce special-margin">
                    <p>
                      El cobro de la primera mensualidad o anualidad del plan
                      seleccionado sera realizado una vez terminada la prueba
                      gratuita de 3 meses
                    </p>
                  </div>

                  <div
                    class="pricing-cards-container special-margin"
                    v-if="!professionalServicesActive"
                  >
                    <div
                      v-bind:class="{
                        'selected-price': selectedPlan === 1,
                        'selected-price-option-selected': selectedPlan !== null,
                      }"
                      class="single-price-card single-price-card-yellow"
                      @click="selectPlan(1)"
                    >
                      <p class="single-price-title">Plan Básico</p>

                      <hr class="single-price-divider" />

                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "$ 499" : "$ 5,988" }}
                      </p>
                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                      </p>
                      <p class="single-price-description">(IVA incluido)</p>

                      <ul class="single-price-list">
                        <li>1 usuario incluido</li>
                        <li>1 almacén incluido</li>
                        <li>1 lista de precios</li>
                        <li>1000 timbres mensuales</li>
                      </ul>
                    </div>

                    <div
                      v-bind:class="{
                        'selected-price': selectedPlan === 2,
                        'selected-price-option-selected': selectedPlan !== null,
                      }"
                      class="single-price-card single-price-card-blue"
                      @click="selectPlan(2)"
                    >
                      <p class="single-price-title">Plan profesional</p>

                      <hr class="single-price-divider" />

                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "$ 999" : "$11,988" }}
                      </p>
                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                      </p>
                      <p class="single-price-description">(IVA incluido)</p>

                      <ul class="single-price-list">
                        <li>1 usuario incluido</li>
                        <li>2 almacén incluido</li>
                        <li>1 lista de precios</li>
                        <li>2000 timbres mensuales</li>
                      </ul>
                    </div>

                    <div
                      v-bind:class="{
                        'selected-price': selectedPlan === 3,
                        'selected-price-option-selected': selectedPlan !== null,
                      }"
                      class="single-price-card single-price-card-green"
                      @click="selectPlan(3)"
                    >
                      <p class="single-price-title">Plan Pro+</p>

                      <hr class="single-price-divider" />

                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "$ 3,999.0" : "$ 47,988.0" }}
                      </p>
                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                      </p>
                      <p class="single-price-description">(IVA incluido)</p>

                      <ul class="single-price-list">
                        <li>5 usuario incluido</li>
                        <li>3 almacén incluido</li>
                        <li>3 lista de precios</li>
                        <li>5000 timbres mensuales</li>
                      </ul>
                    </div>

                    <div
                      v-bind:class="{
                        'selected-price': selectedPlan === 4,
                        'selected-price-option-selected': selectedPlan !== null,
                      }"
                      class="single-price-card single-price-card-purple"
                      @click="selectPlan(4)"
                    >
                      <p class="single-price-title">Plan Master</p>

                      <hr class="single-price-divider" />

                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "$ 16,560" : "$ 198,720" }}
                      </p>
                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                      </p>
                      <p class="single-price-description">(IVA incluido)</p>

                      <ul class="single-price-list">
                        <ul class="single-price-list">
                          <li>10 usuario incluido</li>
                          <li>3 almacén incluido</li>
                          <li>100 lista de precios</li>
                          <li>10000 timbres mensuales</li>
                        </ul>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="pricing-cards-container special-margin"
                    v-if="professionalServicesActive"
                  >
                    <div
                      v-bind:class="{
                        'selected-price': selectedPlan === 5,
                        'selected-price-option-selected': selectedPlan !== null,
                      }"
                      class="single-price-card single-price-card-yellow"
                      @click="selectPlan(5)"
                    >
                      <p class="single-price-title">Plan Básico Servicios</p>

                      <hr class="single-price-divider" />

                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "$ 499" : "$ 5,988" }}
                      </p>
                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                      </p>
                      <p class="single-price-description">(IVA incluido)</p>

                      <ul class="single-price-list">
                        <li>1 usuario incluido</li>
                        <li>1 almacén incluido</li>
                        <li>50 timbres mensuales</li>
                      </ul>
                    </div>

                    <div
                      v-bind:class="{
                        'selected-price': selectedPlan === 6,
                        'selected-price-option-selected': selectedPlan !== null,
                      }"
                      class="single-price-card single-price-card-green"
                      @click="selectPlan(6)"
                    >
                      <p class="single-price-title">Plan Pro+ Servicios</p>

                      <hr class="single-price-divider" />

                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "$ 1,500" : "$ 18,000" }}
                      </p>
                      <p class="single-price-price">
                        {{ !yearlyPaymentActive ? "/Mes" : "/Año" }}
                      </p>
                      <p class="single-price-description">(IVA incluido)</p>

                      <ul class="single-price-list">
                        <li>5 usuario incluido</li>
                        <li>3 almacén incluido</li>
                        <li>200 timbres mensuales</li>
                      </ul>
                    </div>
                  </div>

                  <b-button
                    class="register-button"
                    @click="registerNextStep(2)"
                    :disabled="selectedPlan === null"
                    >Registrarse</b-button
                  >
                </div>
              </b-step-item>
            </b-steps>
          </div>

          <div class="divider-container-register">
            <hr class="solid-divider" />
          </div>
          <div class="change-register-container">
            <p class="change-login-register-action" @click="openLogin()">
              ¿Ya tienes una cuenta? Puedes iniciar sesión con el nombre de tu
              empresa y tu correo
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs utilities-list">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Almacenes</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Control de artículos</a></li>
            <li><a>Compras</a></li>
            <li><a>Control de clientes</a></li>
            <li><a>Venta</a></li>
            <li><a>Bancos</a></li>
            <li><a>Tesorería</a></li>
            <li><a>Calendario</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import passwordMeter from "vue-simple-password-meter";
import { VueRecaptcha } from "vue-recaptcha";
import SecureLS from "secure-ls";
import {
  invoicePlanMoth,
  basicPlanMonth,
  profesionalPlanMonth,
  proPlusPlanMonth,
  masterPlanMonth,
  basicServicesPlanMonth,
  proPlusServicesPlanMonth,
} from "@/utils/env-variables-fns";
// @ is an alias to /src
export default {
  name: "Register",
  components: {
    passwordMeter,
    VueRecaptcha,
  },
  data() {
    return {
      currentStep: 0,
      isUserSucces: false,
      isCompanySuccess: false,
      isPayentSuccess: false,
      registerUser: {
        fullName: "",
        email: "",
        password: "",
        companyMainName: "",
        companyRFC: "",
      },
      errorRegisterUser: {
        fullName: false,
        email: false,
        password: false,
        confirmPassword: false,
        companyMainName: false,
      },
      confirmPassword: "",
      passwordScore: null,
      captchaResponse: null,
      subscriptionPlans: [
        {
          price: invoicePlanMoth,
          quantity: 1,
        },
        {
          price: basicPlanMonth,
          quantity: 1,
        },
        {
          price: profesionalPlanMonth,
          quantity: 1,
        },
        {
          price: proPlusPlanMonth,
          quantity: 1,
        },
        {
          price: masterPlanMonth,
          quantity: 1,
        },
        {
          price: basicServicesPlanMonth,
          quantity: 1,
        },
        {
          price: proPlusServicesPlanMonth,
          quantity: 1,
        },
      ],
      yearlyPaymentActive: false,
      professionalServicesActive: false,
      selectedPlan: null,
    };
  },
  mounted() {},
  methods: {
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateUserInformationStep() {
      let validated = true;
      let message = "";
      if (!this.registerUser.fullName) {
        validated = false;
        this.errorRegisterUser.fullName = true;
        message = "Nombre completos no válido";
        return { validated, message };
      }
      this.errorRegisterUser.fullName = false;

      if (
        !this.registerUser.email ||
        !this.validateEmail(this.registerUser.email)
      ) {
        validated = false;
        message = "Email no válido";
        this.errorRegisterUser.email = true;
        return { validated, message };
      }
      this.errorRegisterUser.email = false;

      if (!this.registerUser.password) {
        validated = false;
        message = "Contraseña no válida";
        this.errorRegisterUser.password = true;
        return { validated, message };
      }
      this.errorRegisterUser.password = false;

      if (this.passwordScore < 3) {
        validated = false;
        message =
          "La contraseña no es segura, debe contener al menos ún número, una mayúscula y al menos 8 carácteres";
        this.errorRegisterUser.password = true;
        return { validated, message };
      }
      this.errorRegisterUser.password = false;

      if (this.registerUser.password !== this.confirmPassword) {
        validated = false;
        message = "Las contraseñas no coinciden";
        this.errorRegisterUser.password = true;
        return { validated, message };
      }
      this.errorRegisterUser.password = false;

      if (!this.captchaResponse) {
        validated = false;
        message = "Captcha no válido";
        return { validated, message };
      }

      return { validated, message };
    },
    validateCompayInformationStep() {
      let validated = true;
      let message = "";

      if (!this.registerUser.companyMainName) {
        validated = false;
        message = "Nombre de la compañía no válido";
        this.errorRegisterUser.companyMainName = true;
        return { validated, message };
      }
      this.errorRegisterUser.companyMainName = false;

      if (!this.registerUser.companyRFC) {
        this.registerUser.companyRFC = "XEXX010101000";
      }

      this.registerUser.companyMainName =
        this.registerUser.companyMainName.toUpperCase();
      this.registerUser.companyRFC = this.registerUser.companyRFC.toUpperCase();

      return { validated, message };
    },
    validatePaymentInformationStep() {
      let validated = true;
      let message = "";

      if (this.selectedPlan === null) {
        validated = false;
        message = "Plan no válido";
        return { validated, message };
      }

      return { validated, message };
    },
    async registerNextStep(step) {
      if (step === 0) {
        const userValidated = this.validateUserInformationStep();
        if (!userValidated.validated) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al validar la información: ${userValidated.message} `,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.isUserSucces = true;
        this.currentStep += 1;
      } else if (step === 1) {
        const companyValidated = this.validateCompayInformationStep();
        if (!companyValidated.validated) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al validar la información: ${companyValidated.message} `,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.isCompanySuccess = true;
        this.currentStep += 1;
      } else if (step === 2) {
        const companyValidated = this.validatePaymentInformationStep();
        if (!companyValidated.validated) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al validar la información: ${companyValidated.message} `,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.isPayentSuccess = true;
        await this.saveCompany();
      }
    },
    async openStripeCheckout(companyId) {
      const response = await this.$store.dispatch("STRIPECHECKOUT", {
        plan: this.subscriptionPlans[this.selectedPlan].price,
        companyId,
      });
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al contactar con el servidor de pago",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      window.open(response.url, "_self");
    },
    openLogin() {
      this.$router.push({ path: "/Login" });
    },
    async saveCompany() {
      const ls = new SecureLS({ isCompression: false });
      const response = await this.$store.dispatch("REGISTERCOMPANY", {
        newUser: this.registerUser,
        plan: this.subscriptionPlans[this.selectedPlan].price,
        yearly: this.yearlyPaymentActive,
      });
      if (!response.success) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al registrar la nueva compañía",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      // Open stripe checkout page
      this.openStripeCheckout(response.userInformation._id);
    },
    onPasswordScore({ score, strength }) {
      this.passwordScore = score;
    },
    verifyCaptcha(response) {
      this.captchaResponse = response;
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
  },
  watch: {
    professionalServicesActive() {
      this.selectedPlan = null;
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
